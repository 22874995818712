import React from "react";
import {headData} from "../../mock/data";
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import {Link} from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/main.scss';


export default () => {
  const {lang} = headData;

  return (
      <>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>LEARN PDF Auto-Opener</title>
          <html lang={lang || 'en'}/>
          <meta name="description" content="Download the LEARN PDF Auto-Opener, for all your PDF opening needs"/>
        </Helmet>
        <section id="hero" className="jumbotron">
          <Container>
            <Fade bottom duration={700} delay={300} distance="30px">
              <h1 className="hero-title text-center" style={{marginBottom: 8}}>
                LEARN PDF Auto-Opener{' '}
                <span role="img" aria-label="emoji">📁</span>
              </h1>
            </Fade>
            <Fade bottom duration={700} delay={300} distance="30px">
              <h3 className="text-center" style={{fontSize: '2.5rem', marginBottom: 15}}>
                A simple browser extension to automagically open annoying embedded PDFs on LEARN in full screen mode!
              </h3>
            </Fade>
            <Fade bottom duration={700} delay={600} distance="30px">
              <h3 className="text-center" style={{fontSize: 25, marginBottom: 4, marginTop: 20, fontWeight: 700}}>
                Install Now
              </h3>

              <p className="hero-cta justify-content-center">
                <Link className="cta-btn cta-btn--hero" to="https://chromewebstore.google.com/detail/learn-pdf-auto-opener/habgfdbalglmgeglhkjbgenlnjkcpjpg" style={{marginRight: 8}}>
                  Chrome
                </Link>

                <Link className="cta-btn cta-btn--hero" to="https://tfinlay.github.io/WebExtensions/learn_pdf_helper/latest.xpi" style={{marginRight: 8}}>
                  Firefox
                </Link>

                <Link className="cta-btn cta-btn--hero" to="https://chromewebstore.google.com/detail/learn-pdf-auto-opener/habgfdbalglmgeglhkjbgenlnjkcpjpg" style={{marginRight: 8}}>
                  Edge
                </Link>
              </p>
            </Fade>

            <Fade bottom duration={700} delay={1500} distance="30px">
              <Link className="text-color-main" to="https://github.com/tfinlay/LEARN-pdf-auto-opener" style={{fontSize: 35, marginBottom: 15}}>
                <i className={`fa fa-github fa-inverse`} />
              </Link>
            </Fade>

          </Container>
        </section>
      </>
  );
}
